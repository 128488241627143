import React, { Component } from 'react';
import { BrowserRouter as Router, Route }  from 'react-router-dom';
import Home from './components/Home';
import LService from './components/LService';
import Testimonials from './components/Testimonials';
import Contactus from './components/Contactus';
import CNavbars from './components/CNavbars';
class App extends Component {
  render() {
    return (
        <Router>
          <div>
            <CNavbars />       
            <Route exact path ="/" component={Home}/>
            <Route exact path ="/services" component={LService}/>
            <Route exact path ="/testimonials" component={Testimonials}/>
            <Route exact path ="/contactus" component={Contactus}/>
          </div>

        </Router>           
    )
}
}

export default App;
