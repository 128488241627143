import React, { Component } from 'react'
import { Container, Row, Col, Image, Button, Jumbotron} from 'react-bootstrap';
import './HeroBanner.css';
export class HeroBanner extends Component {
    render() {
        return (
            <div>
            <Row>
                <Col>
                <Image src="/assets/mainImage.jpg" />
                </Col>                
            </Row>
            <Row>
            <Col>
            <Container className="heroBn">
                <div className="headings">
                <h1>Lee Flooring</h1>
                <h4>Make your floor a part of life.</h4>
                <Button>Get Free Quote</Button>
                </div>
            </Container>
            </Col>                
        </Row>
        </div>
        )    
}
}

export default HeroBanner
