import React, { Component } from 'react'
import { Container, Row, Col} from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Jumbotron from 'react-bootstrap/Jumbotron';
import './Testimonials.css';
export class Testimonials extends Component {
    render() {
        return (
            <Container>
                <Row>                    
                <span className="titles">Testimonials
                </span>
                </Row>
                <Row>
                    <Col>
                    <Carousel>
                        <Carousel.Item>
                            <Container  className="d-block w-100 thread">                              
                                <h2>
                                Thanks Mr Lee. My wife is really happy with the floor.
                                </h2>
                            </Container>
                            <Carousel.Caption>
                            <h4>- Matthew</h4>                            
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>                        
                            <Container  className="d-block w-100 thread">                                
                                <h2>
                                You have did a great job for that old tile kitchen.
                                </h2>
                            </Container>
                            <Carousel.Caption>
                            <h4>- Lara</h4>                            
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Container  className="d-block w-100 thread">                                
                                <h2>
                                I would be recommending you for my friend.
                                </h2>
                            </Container>
                            <Carousel.Caption>
                            <h4>- Margaret</h4>                            
                            </Carousel.Caption>
                        </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Testimonials
