import React, { Component } from 'react'
import { Container, Row, Col, Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import "./Contactus.css";
import {FaPhoneAlt} from "react-icons/fa";
import {FaMapMarkerAlt} from "react-icons/fa";
import MapC from "./MapC";
import axios from 'axios';
export class Contactus extends Component {
    constructor(props) {
        super(props);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeQuery = this.onChangeQuery.bind(this);
        this.state = {
            message: '',
            email: '',
            sent: false,
            buttonText: 'SUBMIT'
        }
      }
    // state = {        
    //     message: '',
    //     email: '',
    //     sent: false,
    //     buttonText: 'SUBMIT'
    // }
    onChangeEmail(e){
        this.setState({
          email:e.target.value
        })
      }
    onChangeQuery(e){
        this.setState({
          message:e.target.value
        })
      }
    formSubmit = (e) => {
        e.preventDefault()
      
        this.setState({
            buttonText: '...sending'
        })
      
        let data = {
            email: this.state.email,
            message: this.state.message
        }
        
        axios({
            method: "POST", 
            url:"http://localhost:3002/send", 
            data:  data
          })
        .then( res => {
            this.setState({ sent: true }, this.resetForm())
        })
        .catch( () => {
            alert("Some error during sending process. You can leave text or call in given number. Sorry")
        })
      }
      resetForm = () => {
        this.setState({
            message: '',
            email: '',
            buttonText: 'SUBMIT'
        })
    }
    render() {
        return (
            <Container>
                <Row>                    
                <span className="titles">Contact Us
                </span>
                </Row>
                <Row>
                    <Col>
                    <Form className="userDetail" onSubmit={ (e) => this.formSubmit(e)}  method="POST">
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" name="email" placeholder="Enter email" value={this.state.name} onChange={this.onChangeEmail}/>
                            <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>                        
                        <Form.Group controlId="ControlTextarea">
                            <Form.Label>Query</Form.Label>
                            <Form.Control name="message" as="textarea" rows="3" value={this.state.message} onChange={this.onChangeQuery}/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                        { this.state.buttonText }
                        </Button>
                    </Form>
                    <div className="contact">
                    <FaPhoneAlt /> 0432231005, 0405005023
                    </div>
                    </Col>
                    <Col>
                        <div>
                        <MapC />
                        </div>
                        <div className="location">
                        <FaMapMarkerAlt /> 15 Bent Ct, Wantirna South, Victoria, Australia
                        </div>
                        </Col>
                </Row>
            </Container>
        )
    }
}

export default Contactus
