import React, { Component } from 'react'
import Card from 'react-bootstrap/Card';
import { Container, Row, Col} from 'react-bootstrap';
import CardGroup from 'react-bootstrap/CardGroup';
import './LService.css';
export class LService extends Component {
    render() {
        return (
            <Container>
                <Row>
                <span className="titles">Services
                </span>
                </Row>
                <Row>
                <CardGroup>
                <Card id="serviceCard">
                    <Card.Img id="servicesImg" variant="top" src="/assets/service1.png" />
                    <Card.Body>
                        <Card.Title>Sanding</Card.Title>
                        <Card.Text>
                        Make the surface smooth and levelled. Followed with polishing, covering gaps, removing odd scratches.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card id="serviceCard">
                    <Card.Img id="servicesImg" variant="top" src="/assets/service2.png" />
                    <Card.Body>
                        <Card.Title>Staining</Card.Title>
                        <Card.Text>
                        Choose the variety of colors for your floor to give awesome view of interios matching ambience with other items. We do any color like black japan, brown japan, ash, maple, etc. using the top brand like Feast Watson.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card id="serviceCard">
                    <Card.Img id="servicesImg" variant="top" src="/assets/service3.png" />
                    <Card.Body>
                        <Card.Title>Fixing</Card.Title>
                        <Card.Text>
                        Broken, termite damaged floorboard, glues and vinyl covered floor; all will be taken good care to find you the hidden treasure in timber.
                        </Card.Text>
                    </Card.Body>
                </Card>
                </CardGroup>
                </Row>
            </Container>
        )
    }
}

export default LService;
