import React, { Component } from 'react'
import { Navbar, Nav, NavItem } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll";
import "./CNavbars.css";
export class CNavbars extends Component {
    scrollToTop = () => {
        scroll.scrollToTop();
      };
    render() {
        return (
            <div>
            <Navbar collapseOnSelect bg="light" expand="lg" fixed="top" >
                <Navbar.Brand onClick={this.scrollToTop}>
                <img
                    alt=""
                    src="/assets/logo.png"
                    className="d-inline-block align-top lgo"                    
                />
                Lee Flooring
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="toggleBtn" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                    <Link
                        activeClass="active"
                        to="home"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className="nav-link"
                        onClick={this.scrollToTop}
                    >
                    Home
                    </Link>
                    <Link
                        activeClass="active"
                        to="services"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className="nav-link"
                    >
                    Services
                    </Link>
                    <Link
                        activeClass="active"
                        to="testimonials"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className="nav-link"
                    >
                    Testimonials
                    </Link>
                    <Link
                        activeClass="active"
                        to="contactus"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className="nav-link"
                    >
                    Contact Us
                    </Link>                  
                    </Nav>
                </Navbar.Collapse>
            </Navbar> 
          </div>          
        )
    }
}

export default CNavbars
