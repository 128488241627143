import React, { Component } from 'react'
import {FaFacebookSquare, FaInstagram} from "react-icons/fa";
import { Container, Row, Col, Image, Button} from 'react-bootstrap';
import './Footer.css';
export class Footer extends Component {
    render() {
        return (
            <Container>
            <Row>
                <Col className="footer">
                    <div>
                        <h4>You can also reach us from</h4>
                    </div>
                    <div className="socialmedia">
                        <span className="icons">
                            <a href ="https://www.facebook.com/Lee-Flooring-110208903894171/">
                            <FaFacebookSquare size={45} />
                            </a>
                        </span>
                        <span className="icons">
                            <a href="https://www.instagram.com/lee.flooring/">
                            <FaInstagram size={45} />
                            </a>
                        </span>
                    </div>
                </Col>
                
            </Row>
            </Container>
        )
    }
}

export default Footer
