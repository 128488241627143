import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Button} from 'react-bootstrap';
import './Home.css';
import LService from './LService';
import Testimonials from './Testimonials';
import Contactus from './Contactus';
import Footer from './Footer';
import HeroBanner from './HeroBanner';
export class Home extends Component {
    render() {
        return (
            <div>
                <section id="home">
                    <HeroBanner  />
                <div  style={{backgroundColor:"#E3E3E3"}}>
                    <Container>                    
                    <p className="description">
                    Lee flooring has been providing services across Melbourne region. With decades of 
                    experience we ensure you the high quality, professional and durable floor service
                    with guarantee.</p>

                    <p className="description">Fulfilling the requirements of hundreds of customer over time, Lee flooring understands
                    what would be best for your floor. Doing every type of finish with range of products
                    over varying wood types has given us plenty of knowledge which would be utilized on
                    curing your floor.</p>

                    <p className="description">Use of high grade equipment, handy tools, durable materials makes 
                    us confident in standing out at best.
                    </p>
                    </Container>
                    </div>
                </section>
                <section id ="services">                   
                            <LService />                                        
                </section>
                <section id="testimonials"  style={{backgroundColor:"#E3E3E3"}}>
                            <Testimonials />
                </section>
                <section id ="contactus">
                    <Contactus />
                </section>
                <section  style={{backgroundColor:"#0e4f9f"}}>
                    <Footer />
                </section>
                

            </div>
        )
    }
}

export default Home;
