import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker  } from 'google-maps-react';
export class MapC extends Component {
    render() {
        const mapStyles = {
            width: '100%',
            height: '100%',
          };
        return (
            <div style={{position : 'relative', width:'40vw', height:'60vh'}}>
                <Map
                    google={this.props.google}
                    zoom={10}
                    style={mapStyles}
                    initialCenter={{ lat: -37.8606757, lng:145.2481393}}
                    />
                    {/* <Marker 
                    title={'The marker`s title will appear as a tooltip.'}
                    name={'SOMA'}
                    icon={{
                        url: "/assets/service1.png",
                        // anchor: new google.maps.Point(32,32),
                        // scaledSize: new google.maps.Size(64,64)
                      }}
                    position={{ lat: -38.00, lng: 146.00}} />    */}
            </div>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDSqRo0rnsGLJhItCzV960qoi2t2g5jfIA'
  })(MapC);
